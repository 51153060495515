import React from 'react'
import { Box, Heading, Image } from 'theme-ui'
import Link from 'gatsby-link'

export default function SuccessPage({ variantName, coverPhoto, name, logo }) {
  return (
    <Box variant={`${variantName}.container`}>
      <Box variant={`${variantName}.coverContainer`}>
        <Image variant={`${variantName}.cover`} src={coverPhoto} alt="Branding Trademark" />
      </Box>
      <Box variant={`${variantName}.content`}>
        <Image variant={`${variantName}.logo`} src={logo} alt="Branding Trademark" />
        <Heading as="h1" variant={`${variantName}.title`}>
          Thank you for your submission.
        </Heading>
        <Heading as="h2" variant={`${variantName}.subtitle`}>
          {name}
        </Heading>
        <Box variant={`${variantName}.buttonContainer`}>
          <Link to="/">Return To Homepage</Link>
        </Box>
      </Box>
    </Box>
  )
}
